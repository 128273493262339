




import 'chartjs-adapter-moment';

import Chart from 'chart.js/auto';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

@Component
export default class ChartComponent extends BaseVue {
  @Prop({ required: true })
  public readonly data: any;

  @Prop({ default: false })
  public readonly forceRedraw!: boolean;

  chart: Chart | null = null;

  mounted() {
    this.drawChart();
  }

  drawChart() {
    if (!this.data) return;
    this.chart = new Chart(this.$el as any, this.data);
    this.chart.update();
  }

  @Watch('data')
  onDataPropsChanged(val: any) {
    if (this.chart) {
      if (!this.forceRedraw) {
        this.chart.update();
      } else {
        this.chart.destroy();
        this.drawChart();
      }
    } else {
      this.drawChart();
    }
  }
}
